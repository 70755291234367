import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import pakmaya from "../../assets/img/pakmaya-basari-hikayesi.jpg"
import odul from "../../assets/img/altin-pakmaya-odul.png"
import pakmayaKurumsal from "../../assets/img/pakmaya-kurumsal.png"
import pakmayaLansman from "../../assets/img/pakmaya-lansman.png"
import pakmayaOtrafik from "../../assets/img/pakmaya-organik-trafik.png"
import pakmayaSites from "../../assets/img/pakmaya-sites.png"
import pakmayaSeo from "../../assets/img/pakmaya-seo.png"
import pakmayaDijitalForm from "../../assets/img/pakmaya-dijital-iletisim-formu.png"
import profesyonellereOzel from "../../assets/img/pakmaya-profesyonellere-ozel.png"
import pakmayaGidaGuvenligi from "../../assets/img/pakmaya-gida-guvenligi.png"
import pakmayaGidaSertifikasi from "../../assets/img/pakmaya-gida-sertifikasi.png"
import pakmayaGlobalPazarlama from "../../assets/img/pakmaya-global-pazarlama.png"
import OtherCaseStudy from "../../components/other-case"
import logo from "../../assets/img/pakmaya-logo.png"
import myildirim from "../../assets/img/teams/22.jpg"
import sedef from "../../assets/img/teams/19.jpg"
import serife from "../../assets/img/teams/20.jpg"
import onay from "../../assets/img/teams/23.jpg"
import gizem from "../../assets/img/teams/24.jpg"
import sultan from "../../assets/img/teams/28.jpg"
import edalabasmaz from "../../assets/img/teams/29.jpg"
import bengu from "../../assets/img/teams/17.jpg"



const ReferancesDetail = () => (
    <Layout>
        <SEO title="Pakmaya Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>Pakmaya</h1>
                <p style={{ maxWidth: 400 }}>Pakmaya temelleri 1923 senesinde Prof. Mustafa Nevzat Bey tarafından kurulan ilaç laboratuvarı ile atılmıştır. Grup 1973 senesinde gıda sektörüne girmeye karar vermiş ve ilk maya fabrikasını İzmit’te kurmuştur.  </p>
                <p style={{ maxWidth: 400, fontSize: 15 }}>İlerleyen zamanlarda Türkiye’de açtığı fabrikaların yanı sıra Amerika ve Romanya’da da fabrikalar açarak 130 ülkeye yaptığı ihracatıyla Pakmaya dünyanın en büyükleri arasında, ilk üç firmadan biridir.</p>
                <img className="pakmaya-headimg" alt="pakmaya mayalı kek harcı paketi" style={{ position: 'absolute', height: 360, right: -90, top: 0, zIndex: -1 }} src={pakmaya} />
            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs">
                    <div className="box">
                        <span>10x</span>
                        <p>Organik trafik artışı</p>
                    </div>
                    <div className="box">
                        <span>+%126</span>
                        <p>Erişilen kitlenin artırılması.</p>
                    </div>

                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/eposta-pazarlama/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 86 86">
                                <g id="Icons-_-Communication-_-Mail-heart" data-name="Icons-/-Communication-/-Mail-heart" transform="translate(0.169 -0.307)">
                                    <rect id="bound" width="86" height="86" transform="translate(-0.169 0.307)" fill="none" />
                                    <path id="Combined-Shape" d="M8.569,2H51.4a3.569,3.569,0,0,1,3.569,3.569V41.262A3.569,3.569,0,0,1,51.4,44.831H8.569A3.569,3.569,0,0,1,5,41.262V5.569A3.569,3.569,0,0,1,8.569,2Zm27.84,7.138c-2.3,0-4.985,2.605-6.425,4.283-1.439-1.678-4.127-4.283-6.425-4.283-4.068,0-6.425,3.173-6.425,7.21q0,6.71,12.849,14.2,12.849-7.5,12.849-13.92C42.834,12.6,40.477,9.138,36.409,9.138Z" transform="translate(13.236 5.138)" fill="#111" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M5.829,6.816,35.123,27.972,64.417,6.816a1.785,1.785,0,0,1,2.829,1.447V44.034a7.138,7.138,0,0,1-7.138,7.138H10.138A7.138,7.138,0,0,1,3,44.034V8.263A1.785,1.785,0,0,1,5.829,6.816Z" transform="translate(8.097 16.643)" fill="#111" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Eposta Pazarlama
                        </div>
                        <div className="box">
                            <Link to="/ui-ux-tasarim/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            UI&UX Tasarım
                        </div>
                    </div>
                </div>
                {/* <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>Dijital Pazarlama Stratejist:</li>
                            <li>Google Ads:</li>
                            <li>SEO:</li>
                            <li>Website Geliştirme:</li>
                        </ul>
                        <ul>
                            <li><img src={simay} />Simay Akkurt</li>
                            <li><img src={eelbeyglu} />Emre Elbeyoğlu</li>
                            <li><img src={serife} />Şerife Aytekin</li>
                            <li><img src={tugce} />Tuğçe Gör</li>
                            <li><img src={onay} />Barış Onay</li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
        <div className="container">
            <div className="testimonial-customer" id="one">
                <p className="pakmaya-center_text" style={{ fontSize: 24, width: '70%', margin: '0 auto' }}>Mayanın yanı sıra fırıncılık, pastacılık, değirmencilik, ev tüketim
ürünlerinde de kalite ve yenilikçi duruşu ile ülkemizin en
güvenilir ve günden güne yükselen gıda markasıdır.</p>
                {/* <div className="author">
                    <div>
                        <img src={customer}></img>
                    </div>
                    <div className="author-name">
                        Emre Altan<br />
                        <span>CEO</span>
                    </div>
                </div> */}
            </div>


            <div className="col-md-12 pakmaya-special py60">
                <div className="col-md-6 pakmaya-special_left">
                    <div>
                        <p>Başarı Hikayesi</p>
                        <img src={logo} alt="pakmaya firma logosu" />
                        <h2>Çalışılan Markalar</h2>
                        <ul>
                            <li>Pakmaya Kurumsal</li>
                            <li>Pakmaya Mutfağın Yıldızı</li>
                            <li>Pakmaya Profesyoneller Dünyası</li>
                            <li>Profesyoneller Dünyası Akademisi</li>
                            <li>Pakmaya Global Pazarlama</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 pakmaya-special_right project-details">
                    <p>Pakmaya Kurumsal Künye</p>
                    <div className="project-details_list">
                        <ul className="col-md-6" id="headList">
                            <li>Marka Yöneticisi</li>
                            <li>Sosyal Medya Danışmanı</li>
                            <li>Sosyal Medya Danışmanı</li>
                            <li>UI / UX Tasarımı</li>
                            <li>İçerik Pazarlama</li>
                            <li>SEO</li>
                            <li>Sosyal Medya Reklam Yönetimi</li>
                            <li>Digital Ads</li>
                            <li>Görsel Tasarım</li>
                        </ul>
                        <ul className="col-md-6">
                            <li><img src={myildirim} alt="Murthan Yıldırım" />Murathan Yıldırım</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={sedef} alt="Sedef Karaman" />Sedef Karaman</li>
                            <li><img src={onay} alt="Barış Onay" />Barış Onay</li>
                            <li><img src={bengu} alt="Bengü Dinçer" />Bengü Dinçer</li>
                            <li><img src={edalabasmaz} alt="Emel Dalabasmaz" />Emel Dalabasmaz</li>
                            <li><img src={gizem} alt="Gizem Altun Bostancı" />Gizem Altun Bostancı</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={sultan} alt="Sultan Dedeoğlu"/>Sultan Dedeoğlu</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="fullwidth-image black referances-detail">
            <div style={{ display: 'flex', alignItems: 'center' }} className="container">
                <div className="col-md-6"><img src={odul} alt="" /></div>
                <div className="col-md-6">
                    <div className="content">
                        <p className="small">Başarı Hikayesi</p>
                        <h2>Ödülle Tescillenmiş<br /> Sosyal Medya Yönetimi</h2>
                        <p>Türkiye’nin en büyük sosyal medya ödülleri platformu <span className="red">@socialmediaawardsturkey</span> Temel Gıda kategorisinde göz bebeğimiz Pakmaya’yı Altın ödülle taçlandırdı. 😎</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="referances-detail">
                <div className="content-section content row py60" id="pkmyKrmsl">
                    <div className="col-md-6">
                        <span className="small">Başarı Hikayesi</span>
                        <h3>Pakmaya Kurumsal</h3>
                        <p>Pakmaya markasının kurumsal imajının güçlü şekilde aktarılması ve hedef kitle üzerinde olumlu algı oluşturması için 2017 senesinden beri dijital pazarlama hizmeti vermekteyiz. Marka stratejisi gereği dijital, pazarlama kanallarının ilk sırasında bulunmakta.</p>
                        <p className="small">
                            Çalışmaya başladığımız günden bu güne kadar organik trafikte 4 kat artış sağladık. Bunun yanı sıra web sitesi içeriklerinin kurgulanması, tasarlanması, uygulanaması ve kitle ile buluşturulması konularında da çalışmaktayız.<br />
                            Web sitesi iyileştirmelerinin yapılması, devamlılığının sağlanması, tasarım ihtiyaçlarının karşılanması kullanıcı deneyimi odaklı olarak marka ekibi ile dirsek teması ile hareket edilerek gerçekleştirilmektedir.
                    </p>
                    </div>
                    <div className="col-md-6">
                        <img src={pakmayaKurumsal} />
                    </div>
                </div>
                <div className="content-section content row">
                    <div className="col-md-6">
                        <img style={{ paddingRight: 50 }} src={pakmayaLansman} alt="" />
                    </div>
                    <div className="col-md-6">
                        <span className="small">Başarı Hikayesi</span>
                        <h3>Lansman Dönemlerine Özel Landing Page Çalışmaları</h3>
                        <p>Lansman dönemlerinde ürünlerin hedef kitleye daha iyi tanıtılması için landing page çalışmaları yapıldı. Landing page’ler ürün özellikleri, ürün tanıtım videoları, ürünün kullanıldığı tarifler ve ürün ile yapılan tariflerden oluşan liste içerikler olarak kurgulandı. </p>
                        <p className="small">
                            Kullanıcılar lansman dönemlerinde landing page’lere yönlendirildi ve toplam 1 milyonun üzerinde sayfa görüntülenmesi alındı. Bu şekilde reklam dönemlerinde dijitalde daha etikili bir ürün tanıtımı yapılmış oldu.
                    </p>
                        <a className="svgLink" target="_blank" href="https://pakmaya.com.tr/cikolatalar/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                            <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                                <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                                    <circle cx="24" cy="24" r="24" stroke="none" />
                                    <circle cx="24" cy="24" r="22.5" fill="none" />
                                </g>
                                <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                            </g>
                        </svg>
                            <span>pakmaya.com.tr/cikolatalar/</span></a>
                    </div>
                </div>
            </div>

            <div style={{ padding: '100px 0px' }} className="container">
                <img style={{ width: "100%" }} src={pakmayaSites} />
            </div>

            <div className="text-center content">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ marginTop: 7 }}>Pakmaya Mutfağın Yıldızı</h2>
                <p style={{ maxWidth: 680, margin: '10px auto 0' }}>Pakmaya’nın iç pazarda b2c kanallarında iletişimini Mutfağın Yıldızı üzerinden yürütmekteyiz. Kullanıcıların içgörülerini okuyup buna göre dijital pazarlama stratejisi üretmek ve uygulamak işimizin temelini oluşturmakta.</p>
            </div>

            <div className="content-section content row py60" id="orgnkTrfk">
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>2 Yıl İçerisinde Organik <br /> Trafikte 10 Kat Büyüme</h3>
                    <p style={{ maxWidth: 415 }}>2018 sonunda hazırlanan Seo uyumlu yeni web sitesi, seo odaklı içerik pazarlama stratejisi ile  2017 yılındaki web sitesi trafiğinin 10 katı üzerinde web trafiği alınmaya başlandı.</p>
                </div>
                <div className="col-md-6">
                    <img src={pakmayaOtrafik} />
                </div>
            </div>

            <div className="text-center content">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ marginTop: 7 }}>On-page Seo Optimizasyonu ile <br /> Google’da Öne Çıkan Sayfalar</h2>
                <p style={{ maxWidth: 515, margin: '10px auto 0' }}>Sitede var olan sayfalar ve tarifler doğru on-page seo taktikleri ile Google’da görünürlüğü arttırılmıştır. </p>
                <p style={{ maxWidth: 680, margin: '15px auto 0' }} className="small">Bir tarif sitesine göre içerik olarak doygun seviyede olan fakat Google görünürlüğü düşük seviyede kalan Mutfağın Yıldızı web sitesi 2017 yılında 2.000 farklı kelime grubundan trafik alırken 2019 yılında 35.000 farklı kelime grubundan potansiyel trafik almaya ulaşmıştır.</p>
            </div>

            <div className="content-section content row py60">
                <div className="col-md-6">
                    <img src={pakmayaSeo} />
                </div>
                <div className="col-md-6 pl100">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Seo Odaklı İçerik<br />Pazarlama Stratejisi </h3>
                    <p style={{ maxWidth: 415 }}>Markayı ve ürünlerine yönlendirecek ve görünürlüğünü arttıracak anahtar kelimeler hedeflenerek hazırlanan ”Seo Contentleri” dışında hedef kitle dinamiklerine ve sosyal medya üzerindeki popüler içeriklere uygun ”lezzet listeleri” içerikleri oluşturulmakta ve sosyal medyada hızlı tüketim içerik akışı yönetilmektedir.</p>
                </div>
            </div>

            <div className="text-center content">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ marginTop: 7 }}>Pakmaya Profesyoneller Dünyası</h2>
                <p style={{ maxWidth: 705, margin: '10px auto 0' }}>Pakmaya’nın endüstriyel tüketime yönelik ürün grubunu doğru hedef kitleyle buluşturmak ve pasta ve fırın ustalarından oluşan hedef kitlenin “dijital buluşma noktası” platformu olarak konumlanmak için 2018 yılında web sitesini yayına aldık.</p>
                <a className="svgLink" style={{ marginTop: 20, justifyContent: 'center' }} target="_blank" href="https://pakmayaprofesyonellerdunyasi.com"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                    <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                            <circle cx="24" cy="24" r="24" stroke="none" />
                            <circle cx="24" cy="24" r="22.5" fill="none" />
                        </g>
                        <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                    </g>
                </svg>
                    <span>pakmayaprofesyonellerdunyasi.com</span></a>
            </div>

            <div className="content-section content row py60" id="frnPsta">
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Fırın ve Pasta Ustalarına<br /> Yönelik Dijital İletişim Platformu</h3>
                    <p style={{ maxWidth: 430 }}>Sektörün bilgilenme ve iletişim gereksinimlerini, güncel ve dinamik bir içerik anlayışıyla karşılayan Pakmaya Profesyoneller Dünyası, fırın ve pasta  ustalarının mesleki yaşamlarına bilgi, beceri ve kazanç boyutları katma amacını taşıyor. </p>
                </div>
                <div className="col-md-6">
                    <img src={pakmayaDijitalForm} />
                </div>
            </div>

        </div>
        <div className="fullwidth-image gray referances-detail">
            <div style={{ display: 'flex', alignItems: 'center' }} className="container">
                <div className="col-md-6"><img src={profesyonellereOzel} /></div>
                <div className="col-md-6">
                    <div className="content">
                        <p className="small">Başarı Hikayesi</p>
                        <h2>Profesyonellere Özel <br /> İçerik Stratejisi</h2>
                        <p>Web sitesinde Pakmaya fırıncılık ve pastacılık ürünlerine ait detaylı bilgiler, kullanım şekilleri ve alanları, videolu ve fotoğraflı tarifler, yeni ekmek ve pasta yapım trendleri, yeni ürün tanıtımları, ücretsiz demo hizmeti ile profesyonellere özel içerikler sunuyor. </p>
                        <p className="small">Profesyonellere özel dijital iletişim platformunun açılışından beri bir milyon üzerinde hedefli kullanıcı web sitesini ziyaret etti. </p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">

            <div className="text-center content py60">
                <span style={{ letterSpacing: 1.2 }}>YEPYENİ DİJİTAL EĞİTİM PLATFORMU</span>
                <h2 style={{ marginTop: 7 }}>Pakmaya Profesyoneller Dünyası Akademisi</h2>
                <p style={{ maxWidth: 705, margin: '10px auto 0' }}>Fırıncılık ve pastacılık sektöründe çalışan işletme sahipleri, şefler, ustalar, tedarikçiler, tüm profesyoneller için Ücretsiz Online Eğitim Platformu kurgularını ve altyapı geliştirmeleri tamamladık.</p>
            </div>

            <div className="content-section content row" id="gidaGvnlgi">
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Gıda Güvenliği Eğitimi</h3>
                    <p style={{ maxWidth: 450 }}>Özyeğin Üniversitesi iş birliği ile ilk eğitim seti olarak da Gıda Güvenliği Eğitim seti hayata geçirildi. Platform; Başlangıç Seviyesi, Temel Seviye, Orta Seviye ve İleri Seviye olmak üzere 4 modül ve 10 dersten oluşan eğitimin sonunda kişiye özel dijital eğitim sertifikası veriliyor. </p>
                </div>
                <div className="col-md-6">
                    <img src={pakmayaGidaGuvenligi} />
                </div>
            </div>
        </div>

        <div className="row py60 certification" style={{ display: 'flex' }}>
            <div className="col-md-7">
                <img style={{ width: '100%' }} src={pakmayaGidaSertifikasi} />
            </div>
            <div className="col-md-5 content" style={{ alignSelf: 'center', transform: 'translateX(-20%)' }}>
                <div style={{ maxWidth: 430 }}>
                    <h2>Gıda Sertifikası</h2>
                    <p>2019 Kasım ayında yayına aldığımız PD pastacı, fırıncı, gastronomi öğrencisi gibi Gıda ile ilişkili binlerce profesyoneli Özyeğin ve Pakmaya imzalı sertifika sahibi yapmaya devam ediyor.</p>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="text-center content">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ marginTop: 7 }}>Pakmaya Global Pazarlama</h2>
                <p style={{ maxWidth: 705, margin: '10px auto 0' }}>Pak İhracat ekibi ile 2018 yılından veri yurtdışı görünürlüğü arttırmak ve ihracat kanallarında yoğun çalışmaktayız. Web sitesi iyileştirmeleri, içerik kurguları ve geliştirmeleri ile Fuar dönemlerinde dijital reklam çalışmalarını yönetmekteyiz.</p>
                <a className="svgLink" style={{ marginTop: 20, justifyContent: 'center' }} target="_blank" href="https://pakmayaprofesyonellerdunyasi.com"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                    <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                            <circle cx="24" cy="24" r="24" stroke="none" />
                            <circle cx="24" cy="24" r="22.5" fill="none" />
                        </g>
                        <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                    </g>
                </svg>
                    <span>pakmayaprofesyonellerdunyasi.com</span></a>
                <img style={{ maxWidth: 600 }} src={pakmayaGlobalPazarlama} />
            </div>

            <div className="pakmaya-kunye py60">
                <div className="text-center">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>KÜNYE</h3>
                </div>
                <div className="col-md-6 kunye-table">
                    <p className="kunye-table_head">Mutfağın Yıldızı Künye</p>
                    <div>
                        <ul className="col-md-6">
                            <li>Proje Yöneticisi</li>
                            <li>Sosyal Medya Danışmanı</li>
                            <li>Arayüz Tasarımı</li>
                            <li>Web Geliştirici</li>
                            <li>İçerik Pazarlama</li>
                            <li>SEO, Dijital Pazarlama</li>
                            <li>Sosyal Medya Reklam Yönetimi</li>
                            <li>Google Ads & Linkedin Ads</li>
                            <li>Görsel Tasarım</li>
                        </ul>
                        <ul className="col-md-6">
                            <li>Murathan Yıldırım</li>
                            <li>Gizem Altun Bostancı, Merve Gümüş</li>
                            <li>Barış Onay</li>
                            <li>Celal Yeşil</li>
                            <li>Bengü Dinçer</li>
                            <li>Emel Dalabasmaz</li>
                            <li>Gizem Altun Bostancı</li>
                            <li>Şerife Aytekin</li>
                            <li>Sultan Dedeoğlu, Şevval Büyük, Barış Onay</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 kunye-table">
                    <p className="kunye-table_head">Pakmaya Profesyoneller Dünyası Künye</p>
                    <div>
                        <ul className="col-md-6">
                            <li>Proje Yöneticisi</li>
                            <li>Sosyal Medya Danışmanı</li>
                            <li>Arayüz Tasarımı</li>
                            <li>Web Geliştirici</li>
                            <li>İçerik Pazarlama</li>
                            <li>SEO, Dijital Pazarlama</li>
                            <li>Sosyal Medya Reklam Yönetimi</li>
                            <li>Digital Ads</li>
                            <li>Görsel Tasarım</li>
                        </ul>
                        <ul className="col-md-6">
                            <li>Murathan Yıldırım</li>
                            <li>Gizem Altun Bostancı, Merve Gümüş</li>
                            <li>Barış Onay</li>
                            <li>Celal Yeşil</li>
                            <li>Bengü Dinçer</li>
                            <li>Emel Dalabasmaz</li>
                            <li>Gizem Altun Bostancı</li>
                            <li>Şerife Aytekin</li>
                            <li>Sultan Dedeoğlu, Şevval Büyük, Barış Onay</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 kunye-table">
                    <p className="kunye-table_head">Profesyoneller Dünyası Akademisi Künye</p>
                    <div>
                        <ul className="col-md-6">
                            <li>Proje Yöneticisi</li>
                            <li>Sosyal Medya Danışmanı</li>
                            <li>Arayüz Tasarımı</li>
                            <li>Web Geliştirici</li>
                            <li>SEO, Dijital Pazarlama</li>
                            <li>Sosyal Medya Reklam Yönetimi</li>
                            <li>Google Ads & Linkedin Ads</li>
                            <li>Görsel Tasarım</li>
                        </ul>
                        <ul className="col-md-6">
                            <li>Murathan Yıldırım</li>
                            <li>Gizem Altun Bostancı, Merve Gümüş</li>
                            <li>Barış Onay</li>
                            <li>Celal Yeşil</li>
                            <li>Emel Dalabasmaz</li>
                            <li>Gizem Altun Bostancı</li>
                            <li>Şerife Aytekin</li>
                            <li>Sultan Dedeoğlu, Şevval Büyük, Barış Onay</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 kunye-table">
                    <p className="kunye-table_head">Pakmaya Global Pazarlama Künye</p>
                    <div>
                        <ul className="col-md-6">
                            <li>Marka Yöneticisi</li>
                            <li>Arayüz Tasarımı</li>
                            <li>İçerik Pazarlama</li>
                            <li>Web Geliştirici</li>
                        </ul>
                        <ul className="col-md-6">
                            <li>Simay Akkurt</li>
                            <li>Barış Onay</li>
                            <li>Bengü Dinçer</li>
                            <li>Barış Arslan</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
